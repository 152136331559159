import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Top',
        component: () => import('@/views/Top.vue'),
        meta: {
            title: 'トップ',
        },
    },
    {
        path: '/revoist',
        name: 'Revoist',
        component: () => import('@/views/Revoist.vue'),
        meta: {
            title: 'レボイストとは',
        },
    },
    {
        path: '/personal-training',
        name: 'PersonalTraining',
        component: () => import('@/views/PersonalTraining.vue'),
        meta: {
            title: 'パーソナルトレーニング',
        },
    },
    {
        path: '/price',
        name: 'Price',
        component: () => import('@/views/Price.vue'),
        meta: {
            title: '料金・会員種別',
        },
    },
    {
        path: '/scene',
        name: 'Scene',
        component: () => import('@/views/Scene.vue'),
        meta: {
            title: 'ジム風景',
        },
    },
    {
        path: '/ourteam',
        name: 'OurTeam',
        component: () => import('@/views/OurTeam.vue'),
        meta: {
            title: 'トレーナー',
        },
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        component: () => import('@/views/Testimonial.vue'),
        meta: {
            title: 'お客様の声',
        },
    },
    {
        path: '/testimonial-1',
        name: 'Testimonial1',
        component: () => import('@/views/Testimonial1.vue'),
        meta: {
            title: 'お客様の声①',
        },
    },
    {
        path: '/testimonial-2',
        name: 'Testimonial2',
        component: () => import('@/views/Testimonial2.vue'),
        meta: {
            title: 'お客様の声②',
        },
    },
    {
        path: '/testimonial-3',
        name: 'Testimonial3',
        component: () => import('@/views/Testimonial3.vue'),
        meta: {
            title: 'お客様の声③',
        },
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/views/Faq.vue'),
        meta: {
            title: 'よくあるご質問',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
            title: '体験レッスン',
        },
    },
    {
        path: '/gyms',
        name: 'Gyms',
        component: () => import('@/views/Gyms.vue'),
        meta: {
            title: '店舗一覧',
        },
    },
    {
        path: '/access',
        name: 'Access',
        component: () => import('@/views/Access.vue'),
        meta: {
            title: '行徳駅前店',
        },
    },
    {
        path: '/access-2',
        name: 'Access2',
        component: () => import('@/views/Access2.vue'),
        meta: {
            title: '妙典末広店',
        },
    },
    {
        path: '/access-3',
        name: 'Access3',
        component: () => import('@/views/Access3.vue'),
        meta: {
            title: '南行徳駅前店',
        },
    },
    {
        path: '/access-4',
        name: 'Access4',
        component: () => import('@/views/Access4.vue'),
        meta: {
            title: '妙典イオン前店',
        },
    },
    {
        path: '/access-5',
        name: 'Access5',
        component: () => import('@/views/Access5.vue'),
        meta: {
            title: '妙典塩焼店',
        },
    },
    {
        path: '/access-6',
        name: 'Access6',
        component: () => import('@/views/Access6.vue'),
        meta: {
            title: '葛西環七通り店',
        },
    },
    {
        path: '/access-7',
        name: 'Access7',
        component: () => import('@/views/Access7.vue'),
        meta: {
            title: '行徳駅前南店',
        },
    },
    {
        path: '/access-8',
        name: 'Access8',
        component: () => import('@/views/Access8.vue'),
        meta: {
            title: '南行徳SOCOLA店',
        },
    },
    {
        path: '/access-9',
        name: 'Access9',
        component: () => import('@/views/Access9.vue'),
        meta: {
            title: '平井蔵前橋通り店',
        },
    },
    {
        path: '/access-10',
        name: 'Access10',
        component: () => import('@/views/Access10.vue'),
        meta: {
            title: '浦安駅前店',
        },
    },
    {
        path: '/access-11',
        name: 'Access11',
        component: () => import('@/views/Access11.vue'),
        meta: {
            title: '妙典三丁目店',
        },
    },
    {
        path: '/access-12',
        name: 'Access12',
        component: () => import('@/views/Access12.vue'),
        meta: {
            title: '葛西駅前店',
        },
    },
    {
        path: '/access-13',
        name: 'Access13',
        component: () => import('@/views/Access13.vue'),
        meta: {
            title: '行徳駅前一丁目店',
        },
    },
    {
        path: '/access-14',
        name: 'Access14',
        component: () => import('@/views/Access14.vue'),
        meta: {
            title: '南行徳駅新井店',
        },
    },
    {
        path: '/access-15',
        name: 'Access15',
        component: () => import('@/views/Access15.vue'),
        meta: {
            title: '平井ゆりのき橋通り店',
        },
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import('@/views/Company.vue'),
        meta: {
            title: '会社概要',
        },
    },
    {
        path: '/recruit',
        name: 'Recruit',
        component: () => import('@/views/Recruit.vue'),
        meta: {
            title: '求人情報',
        },
    },
    {
        path: '*',
        name: 'Notfound',
        component: () => import('@/views/NotFound.vue'),
        meta: {
            title: '404',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    const reg = /^(.*).html$/
    const match = to.path.match(reg)

    if (match) {
        let target = '/'
        console.log(match)
        if (match[1] !== '/index') target = match[1]
        next(target)
    }
    next()
})

router.afterEach(() => {
    window.scroll(0, 0)
})

export default router
